<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="类型" prop="type">
                <a-select v-model="queryParam.departureArea">
                  <a-select-option :value="1">美签</a-select-option>
                  <a-select-option :value="2">申根</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :md="8" :sm="24">
              <a-form-item label="出发区域 " prop="departureArea">
                <a-select v-model="queryParam.departureArea">
                  <a-select-option :value="1">国内</a-select-option>
                  <a-select-option :value="2">英国</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="8" :sm="24">
                <a-form-model-item label="标签" prop="label" >
                  <a-select v-model="queryParam.label">
                    <a-select-option v-for="item in labelList" :value="item.id">{{item.dictLabel}}</a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-model-item label="保险公司" prop="insuranceCompany" >
                  <a-select v-model="queryParam.label">
                    <a-select-option v-for="item in companyList" :value="item.id">{{item.dictLabel}}</a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>

            </template>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'"/>
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['insurance:insurance:add']">
          <a-icon type="plus" />新增
        </a-button>
        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['insurance:insurance:edit']">
          <a-icon type="edit" />修改
        </a-button>
        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['insurance:insurance:remove']">
          <a-icon type="delete" />删除
        </a-button>
<!--        <a-button type="primary" @click="handleExport" v-hasPermi="['insurance:insurance:export']">-->
<!--          <a-icon type="download" />导出-->
<!--        </a-button>-->
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="image" slot-scope="text, record">
           <img v-viewer  style="width: 48px;height: 48px;margin-top: 5px;margin-right: 5px;" v-if="record.logo"
                :src="record.logo" preview="头像"/>
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['insurance:insurance:edit']" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['insurance:insurance:edit']">
            <a-icon type="edit" />修改
          </a>
          <a-divider type="vertical" v-hasPermi="['insurance:insurance:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['insurance:insurance:remove']">
            <a-icon type="delete" />删除
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageInsurance,listInsurance, delInsurance } from '@/api/insurance/insurance'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'
import {getDicts} from "@/api/system/dict/data";

export default {
  name: 'Insurance',
  components: {
    CreateForm
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      labelList:[],
      companyList:[],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        type: null,
        departureArea: null,
        logo: null,
        title: null,
        price: null,
        content: null,
        label: null,
        insuranceCompany: null,
        saleAmount: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        // {
        //   title: 'id',
        //   dataIndex: 'id',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '保险类型',
          dataIndex: 'type',
          ellipsis: true,
          align: 'center',
          customRender(f){
            if(f ==1 ){
              return '美签'
            }else{
              return '申根'
            }
          }
        },
        {
          title: '出发区域',
          dataIndex: 'departureArea',
          ellipsis: true,
          align: 'center',
          customRender(f){
            if(f ==1 ){
              return '国内'
            }else{
              return '英国'
            }
          }
        },
        {
          title: '海报图',
          dataIndex: 'logo',
          ellipsis: true,
          align: 'center',
          scopedSlots: {customRender: 'image'}
        },
        {
          title: '标题',
          dataIndex: 'title',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '价格',
          dataIndex: 'price',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '内容',
          dataIndex: 'content',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '标签',
          dataIndex: 'labelName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '保险公司',
          dataIndex: 'companyName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '销量',
          dataIndex: 'saleAmount',
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '插入时间',
        //   dataIndex: 'createTime',
        //   scopedSlots: { customRender: 'createTime' },
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '备注',
        //   dataIndex: 'remark',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
    this.initLabel()
  },
  activated() {
    this.initLabel()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    initLabel(){
      getDicts('insurance_label').then(res=>{
        this.labelList = res.data
      })
      getDicts('insurance_company').then(res=>{
        this.companyList = res.data
      })
    },
    /** 查询保险列表 */
    getList () {
      this.loading = true
     pageInsurance(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        type: undefined,
        departureArea: undefined,
        logo: undefined,
        title: undefined,
        price: undefined,
        content: undefined,
        label: undefined,
        insuranceCompany: undefined,
        saleAmount: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delInsurance(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('insurance/insurance/export', {
            ...that.queryParam
          }, `保险_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
